<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="1000px"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Create Client</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <v-row>
          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.type_id"
                label="Client type"
                item-text="text"
                item-value="index"
                :items="serverData.client_types"
                :error-messages="typeErrors"
                @blur="$v.formData.type_id.$touch()"
              ></v-select>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.name"
                label="Name"
                :error-messages="nameErrors"
                @blur="$v.formData.name.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.systemCode"
                label="System Code"
                :error-messages="systemCodeErrors"
                @blur="$v.formData.systemCode.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.email"
                label="Email"
              />
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.update_order_status"
                label="Update order status"
                item-text="text"
                item-value="index"
                :items="serverData.statuses"
              ></v-select>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.won_date"
                    label="Won date"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="formData.won_date"></v-date-picker>
              </v-menu>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <v-file-input
              v-model="formData.photo"
              show-size
              counter
              multiple
              dense
              hide-details
              outlined
              clearable
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Photo"
            />
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                type="number"
                :min="0"
                v-model="formData.staff_account_limit"
                label="Staff account limit"
                :error-messages="accountLimitErrors"
                @blur="$v.formData.staff_account_limit.$touch()"
                @change="() => validateMinValue('staff_account_limit', 0)"
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.shopCode"
                label="Shop Code"
                :error-messages="shopCodeErrors"
                @blur="$v.formData.shopCode.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.shopName"
                label="Shop Name"
                :error-messages="shopNameErrors"
                @blur="$v.formData.shopName.$touch()"
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-autocomplete
                label="Customer Offering Type"
                v-model="formData.offer_type_id"
                :items="serverData.client_offer_types"
                item-text="text"
                item-value="index"
                dense
                outlined
                clearable
              />
            </div>
          </v-col>

          <v-col cols="12" md="4" v-if="formData.type_id === 1">
            <div class="h-55px">
              <v-select
                dense
                outlined
                clearable
                v-model="formData.aggregator_id"
                label="Aggregator"
                item-text="text"
                item-value="index"
                :items="serverData.aggregators"
              ></v-select>
            </div>
          </v-col>

          <v-col cols="12" :md="formData.type_id === 1 ? 4 : 6">
            <div class="h-55px">
              <v-autocomplete
                dense
                outlined
                multiple
                clearable
                v-model="formData.fulfillment_types"
                label="Fulfillment type"
                item-text="text"
                item-value="index"
                :items="serverData.fulfillment_types"
              ></v-autocomplete>
            </div>
          </v-col>

          <v-col cols="12" :md="formData.type_id === 1 ? 4 : 6">
            <div class="h-55px">
              <v-text-field
                v-model="formData.shipping_label_name"
                label="Shipping label name"
                dense
                outlined
                multiple
                clearable
                :error-messages="shippingLabelNameErrors"
                @blur="$v.formData.shipping_label_name.$touch()"
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-autocomplete
                label="RVP pickup schedule modes"
                v-model="formData.rvp_pickup_scheduled_mode"
                :items="serverData.rvp_pickup_scheduled_modes"
                item-text="text"
                item-value="index"
                dense
                outlined
              />
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="h-55px">
              <v-text-field
                v-model="formData.mrsal_code"
                label="MRSAL Code"
                dense
                outlined
                multiple
                clearable
              />
            </div>
          </v-col>

          <div
            class="col-12 col-sm-6 col-md-4 d-flex align-center justify-space-between mt-0 pt-0"
          >
            <h5 class="mb-0 mt-0 pt-0 font-weight-regular">
              Instant AWB generation
            </h5>
            <v-switch
              v-model="formData.instant_awb"
              dense
              hide-details
              class="mt-0 pt-0"
              :true-value="1"
              :false-value="0"
            />
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 d-flex align-center justify-space-between mt-0 pt-0"
          >
            <h5 class="mb-0 mt-0 pt-0 font-weight-regular">
              Instant RVP AWB generation
            </h5>
            <v-switch
              v-model="formData.instant_rvp_awb"
              dense
              hide-details
              class="mt-0 pt-0"
              :true-value="1"
              :false-value="0"
            />
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 d-flex align-center justify-space-between mt-0 pt-0"
          >
            <h5 class="mb-0 mt-0 pt-0 font-weight-regular">
              Consider base sku price
            </h5>
            <v-switch
              v-model="formData.consider_base_sku_price"
              dense
              hide-details
              class="mt-0 pt-0"
              :true-value="1"
              :false-value="0"
            />
          </div>

          <ShipperAddressForm
            ref="shipperAddress"
            v-if="isShipperAddressFormVisible"
            :countries="serverData.countries"
            :pageLoader="pageLoader"
          />

          <v-col cols="12">
            <div class="h-55px">
              <v-text-field
                dense
                outlined
                clearable
                v-model="formData.description"
                label="Description"
              />
            </div>
          </v-col>
        </v-row>

        <v-card elevation="0">
          <v-toolbar-title
            class="primary lighten-1 h3 text-white text-center mt-4 pa-1"
            >Sales</v-toolbar-title
          >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="sale in serverData.sales"
                :key="sale.value"
              >
                <v-switch
                  class="mt-0 pt-0"
                  v-model="formData.sales"
                  color="primary"
                  hide-details
                  :label="sale.text"
                  :value="sale.index"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card elevation="0">
          <v-toolbar-title
            class="primary lighten-1 h3 text-white text-center mt-4 pa-1"
            >Services</v-toolbar-title
          >
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="service in serverData.services"
                :key="service.value"
              >
                <v-switch
                  class="mt-0 pt-0"
                  hide-details
                  v-model="formData.services"
                  color="primary"
                  :label="service.text"
                  :value="service.index"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ShipperAddressForm from "@/own/components/customers/clients/ShipperAddressForm.vue";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import SwalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "AddItem",
  components: { ShipperAddressForm },
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      systemCode: { required },
      name: { required },
      type_id: { required },
      staff_account_limit: { required },
      shopCode: { required },
      shopName: { required },
      shipping_label_name: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    menu: false,
    formData: {
      systemCode: null,
      update_order_status: null,
      won_date: null,
      shopCode: null,
      shopName: null,
      name: null,
      email: null,
      description: null,
      photo: null,
      sales: [],
      services: [],
      type_id: 1,
      aggregator_id: null,
      staff_account_limit: 5,
      fulfillment_types: [],
      shipping_label_name: null,
      offer_type_id: null,
      rvp_pickup_scheduled_mode: 0,
      instant_awb: false,
      instant_rvp_awb: false,
      consider_base_sku_price: 0,
      mrsal_code: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;

      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });

      // append shipper address
      if (this.isShipperAddressFormVisible) {
        const shipper_address = {
          ...this.$refs.shipperAddress.shipper_address,
        };

        shipper_address.state = shipper_address.state.id;
        shipper_address.city = shipper_address.city.id;

        if (shipper_address.area) {
          shipper_address.area = shipper_address.area.id;
        }
        Object.keys(shipper_address).forEach((prop) => {
          data.append(`shipper_address[${prop}]`, shipper_address[prop]);
        });
      }
      return data;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/authorizations/clients/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitCreateForm() {
      this.$v.$touch();

      let isShipperAddressFormInvalid = false;
      if (this.isShipperAddressFormVisible) {
        this.$refs.shipperAddress.$v.$touch();
        isShipperAddressFormInvalid = this.$refs.shipperAddress.$v.$invalid;
      }

      if (!this.$v.$invalid && !isShipperAddressFormInvalid) {
        this.pageLoader(true);
        const data = this.convertToFormData();
        ApiService.post("/authorizations/clients/store", data)
          .then(() => {
            SwalAdded();
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetForm() {
      this.$v.$reset();
      this.formData = {
        systemCode: null,
        update_order_status: null,
        won_date: null,
        shopCode: null,
        shopName: null,
        name: null,
        email: null,
        description: null,
        photo: null,
        sales: [],
        services: [],
        type_id: 1,
        aggregator_id: null,
        staff_account_limit: 5,
        fulfillment_types: [],
        shipping_label_name: null,
        offer_type_id: null,
        rvp_pickup_scheduled_mode: 0,
        instant_awb: false,
        instant_rvp_awb: false,
        consider_base_sku_price: 0,
        mrsal_code: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
    systemCodeErrors() {
      return this.handleFormValidation("systemCode");
    },
    nameErrors() {
      return this.handleFormValidation("name");
    },
    accountLimitErrors() {
      return this.handleFormValidation("staff_account_limit");
    },
    typeErrors() {
      return this.handleFormValidation("type_id");
    },
    shopCodeErrors() {
      return this.handleFormValidation("shopCode");
    },
    shopNameErrors() {
      return this.handleFormValidation("shopName");
    },
    isShipperAddressFormVisible() {
      return (
        this.formData.fulfillment_types.includes(9) ||
        this.formData.fulfillment_types.includes(18)
      );
    },
    shippingLabelNameErrors() {
      return this.handleFormValidation("shipping_label_name");
    },
  },
};
</script>
