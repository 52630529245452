<template>
  <v-dialog
    v-model="tokenDialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h4 class="mb-0 font-weight-bolder">Generated token</h4>
      </div>
      <div
        class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7 px-0"
      >
        <p class="font-size-h5">
          <!-- <span class="font-weight-bolder">Generated token</span> -->
        </p>
        <div class="highlight">
          <button class="highlight-copy btn" @click="copy">
            {{ buttonText }}
          </button>
          <div class="highlight-code">
            <span>{{ refresh }}</span>
          </div>
        </div>
      </div>

      <div class="py-4 mx-6 mx-xl-16 d-flex flex-grow-1 align-end justify-end">
        <button
          type="submit"
          class="btn btn-info px-5 py-3 ls1"
          @click="toggleModal"
        >
          Close
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "TokenModal",
  props: ["refresh"],
  data: () => ({ tokenDialog: false, buttonText: "copy", tokenData: "" }),
  methods: {
    copy() {
      navigator.clipboard.writeText(this.refresh);
      this.buttonText = "copied";
    },
    // handleClose() {
    //   this.tokenDialog = false;
    //   this.refresh && this.refresh();
    // },
    toggleModal() {
      this.tokenDialog = !this.tokenDialog;
    },
  },
};
</script>

<style scoped>
.highlight {
  position: relative;
  background: #1e1e3f;
  border-radius: 0.5rem;
  padding: 1rem;
}
.highlight .highlight-copy {
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}

.highlight .highlight-copy:hover {
  transition: all 0.2s ease;
  background-color: #323268;
  color: #009ef7;
}

.highlight span {
  width: 85%;
  color: #a5c261;
  text-align: left;
  word-break: break-all;
  font-family: "Operator Mono", "Fira Code", Consolas, Monaco, "Andale Mono",
    "Ubuntu Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5px;
}
</style>
